import { ICompleteForm } from '../scholarship-ai';

export const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

export const indexToLetter = (index: number): string => {
  if (index >= 0 && index < alphabet.length) {
    return alphabet[index];
  }

  throw new Error('Índice fora do intervalo válido');
};

export const convertToQuestions = (
  form: ICompleteForm,
): { [key: string]: string } => {
  const convertedForm: { [key: string]: string } = {};

  let questionNumber = 1;

  for (const key in form) {
    if (alphabet.includes(form[key as keyof ICompleteForm])) {
      convertedForm[`pergunta_${questionNumber}`] =
        form[key as keyof ICompleteForm];

      questionNumber += 1;
    } else {
      convertedForm[key as keyof ICompleteForm] =
        form[key as keyof ICompleteForm];
    }
  }

  return convertedForm;
};
