import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Integralize from '../pages/integralize/integralize';
// import Subscription from '../pages/subscription/subscription';
import Event from '../pages/event/event';
import ScholarshipAi from '../pages/scholarship-ai/scholarship-ai';
import Trial from '../pages/trial/trial';

// const CheckoutRedirect = () => {
//   const { selectedValue } = useParams();

//   if (selectedValue) {
//     window.location.href = `https://forms.xpeducacao.com.br/checkout-pre-bf/?igti_checkout_products=${selectedValue}`;
//     return null;
//   } else {
//     window.location.href = 'https://pagamento.xpeducacao.com.br/';
//     return null;
//   }
// };

const RouterApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
          path="inscricao-curso-gratuito"
          element={<Subscription />}
        ></Route> */}
        {/* <Route path="integralize" element={<Integralize />}></Route> */}
        {/* <Route path="inscricao-evento" element={<Event />}></Route> */}
        <Route
          path="scholarship-ai"
          element={<ScholarshipAi />}
          // element={<CheckoutRedirect />}
        >
          <Route
            path=":selectedValue"
            element={<ScholarshipAi />}
          />
        </Route>
        <Route
          path="trial"
          element={<Trial />}
          // element={<CheckoutRedirect />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouterApp;
