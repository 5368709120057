import EastIcon from '@mui/icons-material/East';
import { Radio, RadioGroup } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { AlertIcon } from '../components/icons';
import {
  BackButton,
  ButtonsDivRadioForm,
  MainForm,
  ProgressButton,
  RadioGroupDiv,
  RadioLabel,
  StyledAlert,
  StyledFormLabel,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';
import { ICompleteForm } from '../scholarship-ai';
import { alphabet, indexToLetter } from '../utils';
import api from '../utils/api-config';

interface IStepForm {
  ageGroup: string;
  genre: string;
  humanRace: string;
  educationLevel: string;
  highSchoolType: string;
  graduationType: string;
}

interface IQuestion {
  question: string;
  answers: string[];
  map: keyof IStepForm;
}

const Step2 = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setStepForm((prev) => {
      const mergedForm = { ...prev };

      for (const key in mergedForm) {
        mergedForm[key as keyof IStepForm] = props.form[key as keyof IStepForm];
      }

      return mergedForm;
    });
  }, [props.form]);

  const [stepForm, setStepForm] = useState<IStepForm>({
    ageGroup: '',
    genre: '',
    humanRace: '',
    educationLevel: '',
    highSchoolType: '',
    graduationType: '',
  });

  const [stepErrors, setStepErrors] = useState<{
    [key: string]: boolean;
  }>({
    ageGroup: false,
    genre: false,
    humanRace: false,
    educationLevel: false,
    highSchoolType: false,
    graduationType: false,
  });

  const questions: IQuestion[] = [
    {
      question: 'Qual é a sua faixa etária?',
      answers: [
        'Menos que 18 anos',
        '18-25 anos',
        '26-35 anos',
        '36-45 anos',
        'Acima de 45 anos',
      ],
      map: 'ageGroup',
    },
    {
      question: 'Qual é o seu gênero?',
      answers: ['Masculino', 'Feminino', 'Outro', 'Prefiro não responder'],
      map: 'genre',
    },
    {
      question: 'Você se identifica com qual raça e/ou cor?',
      answers: [
        'Amarela',
        'Branca',
        'Indígena',
        'Parda',
        'Preta',
        'Outra',
        'Não sei responder',
        'Prefiro não responder',
      ],
      map: 'humanRace',
    },
    {
      question: 'Qual é o seu nível de educação formal?',
      answers: [
        'Ensino Fundamental',
        'Ensino Médio/Técnico',
        'Graduação Incompleta',
        'Graduado(a)',
        'Pós Graduado(a)',
        'Mestre',
        'Doutor(a)',
      ],
      map: 'educationLevel',
    },
    {
      question: 'Onde concluiu o ensino médio?',
      answers: [
        'Escola pública regular',
        'Escola pública técnico',
        'Escola particular',
        'Não concluído',
      ],
      map: 'highSchoolType',
    },
    {
      question: 'Onde concluiu a graduação?',
      answers: ['Faculdade pública', 'Faculdade particular', 'Não concluído'],
      map: 'graduationType',
    },
  ];

  const handleBack = () => {
    if (props.hasCourse) {
      props.updateStep(0);
    } else {
      props.updateStep(1);
    }
  };

  const validateRadios = () => {
    let hasErrors = false;

    for (const key in stepForm) {
      if (
        !stepForm[key as keyof IStepForm] ||
        stepForm[key as keyof IStepForm] === ''
      ) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }
    }

    return hasErrors;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validScholarity = () => {
      const educationQuestionIndex = questions.findIndex(
        (q) => q.map === 'educationLevel',
      );

      const educationLetterIndex = alphabet.indexOf(stepForm.educationLevel);

      switch (questions[educationQuestionIndex].answers[educationLetterIndex]) {
        case 'Graduação Incompleta':
          return 'Ensino Superior Incompleto';

        case 'Graduado(a)':
          return 'Ensino Superior Completo';

        case 'Pós Graduado(a)':
          return 'Pós-graduação/MBA';

        case 'Mestre':
          return 'Mestrado';

        case 'Doutor(a)':
          return 'Doutorado';

        default:
          return questions[educationQuestionIndex].answers[
            educationLetterIndex
          ];
      }
    };

    const incomingData = new URL(window.location.href);
    const productName = decodeURI(incomingData.pathname.split('/')[2]);
    const data = {
      name: incomingData.searchParams.get('nome'),
      email: incomingData.searchParams.get('email'),
      phone: incomingData.searchParams.get('telefone'),
      product_name: productName,
      scholarity: validScholarity(),
    };

    const {
      data: { success },
    } = await api.post('/scholarship-ai/update', data, {
      timeout: 60000,
    });

    if (!validateRadios()) {
      props.updateStep(3);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <MainForm onSubmit={handleSubmit}>
      {questions.map(({ question, answers, map }, index) => {
        return (
          <RadioGroupDiv key={index}>
            {stepErrors[map] ? (
              <StyledAlert icon={<AlertIcon fontSize="inherit" />} key={index}>
                Por favor, responda a questão abaixo
              </StyledAlert>
            ) : (
              <></>
            )}
            <RadioLabel>{question}</RadioLabel>
            <RadioGroup
              value={stepForm[map]}
              onChange={({ target: { value } }) => {
                setStepForm((prev) => {
                  return { ...prev, [map]: value };
                });
                props.updateForm((prev: ICompleteForm) => {
                  return { ...prev, [map]: value };
                });
                setStepErrors((prev) => {
                  return { ...prev, [map]: false };
                });
              }}
            >
              {answers.map((answer, index) => {
                return (
                  <StyledFormLabel
                    key={index}
                    value={indexToLetter(index)}
                    control={<Radio />}
                    label={answer}
                  />
                );
              })}
            </RadioGroup>
          </RadioGroupDiv>
        );
      })}
      <ButtonsDivRadioForm>
        <ProgressButton type="submit" endIcon={<EastIcon />}>
          Continuar
        </ProgressButton>
        <BackButton type="button" variant="outlined" onClick={handleBack}>
          Voltar
        </BackButton>
      </ButtonsDivRadioForm>
    </MainForm>
  );
};

export { Step2 };
