import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import InterestsIcon from '@mui/icons-material/Interests';
import TerminalIcon from '@mui/icons-material/Terminal';
import ErrorIcon from '@mui/icons-material/Error';

export const Step1Icon = AssignmentIndIcon;
export const Step1ProductIcon = MenuBookIcon;
export const Step2Icon = AccountCircleIcon;
export const Step3Icon = SupervisedUserCircleIcon;
export const Step4Icon = InterestsIcon;
export const Step5Icon = TerminalIcon;
export const AlertIcon = ErrorIcon;
