import { useEffect } from 'react';
import {
  StyledLink,
  ProgressButton,
  StyledHeading,
  StyledParagraph,
  StyledTextContainer,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';

const Step4 = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledTextContainer sx={{ marginTop: '40px' }}>
      <StyledHeading>Inscrição realizada com sucesso!</StyledHeading>
      <StyledParagraph>
        Você agora tem acesso a um período de teste gratuito de 15 dias, durante
        o qual poderá explorar todo o conteúdo disponível em nossa plataforma.
        Aproveite este tempo para mergulhar nos materiais de estudo, participar
        das atividades e expandir seus conhecimentos. Ao final do período de
        trial, será realizada a cobrança, você pode cancelar seu trial a
        qualquer momento em Conta {'->'} Serviços e Requerimentos no ambiente de
        ensino.
      </StyledParagraph>
      <StyledParagraph>
        Para começar sua jornada, clique no botão abaixo e você será direcionado
        diretamente para a área de estudo, onde todo o conteúdo está disponível
        para você. Suas informações de login e senha serão enviadas por e-mail
        em alguns minutos. Fique atento à sua caixa de entrada!
      </StyledParagraph>
      <ProgressButton
        style={{ width: '180px' }}
        onClick={() => {
          window.open('https://online.igti.com.br/login/canvas', '_blank');
        }}
      >
        Começar a Estudar
      </ProgressButton>
      <StyledParagraph>
        Estamos ansiosos para acompanhar seu progresso e ajudá-lo a alcançar
        seus objetivos!
      </StyledParagraph>
    </StyledTextContainer>
  );
};

export { Step4 };
