import { useState, useEffect, FormEvent } from 'react';
import EastIcon from '@mui/icons-material/East';
import {
  MainForm,
  LargeInput,
  ProgressButton,
  StyledInputAlert,
  StyledTextContainer,
  StyledHeading,
  StyledParagraph,
  StyledLink,
  ButtonsDivRadioForm,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';
import { CpfMask, PhoneMask } from '../components/masks';
import { ICompleteForm } from '../trial';
import { AlertIcon } from '../components/icons';
import {
  TrialInputsLabels,
  // regexCPF,
  // regexEmail,
  // regexPhone,
} from '../utils/constants';
import { Checkbox, FormControlLabel } from '@mui/material';
import api from '../utils/api-config';
import {
  BlockedDiv,
  CircularSpinner,
} from '../../../shared/components/spinners';
import { useToast } from '../../../shared/components/Toast';

interface IStepForm {
  name: string;
  email: string;
  cpf: string;
  phone: string;
}

// interface IStepTracker {
//   personal_data: string;
// }

const Step1 = (props: IProps) => {
  const [checked, setChecked] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const { addToast } = useToast();

  const [stepForm, setStepForm] = useState<IStepForm>({
    name: '',
    email: '',
    cpf: '',
    phone: '',
  });

  // const [stepTracker, setStepTracker] = useState<IStepTracker>({
  //   personal_data: '',
  // });

  useEffect(() => {
    setStepForm((prev) => {
      const mergedForm = { ...prev };

      for (const key in mergedForm) {
        mergedForm[key as keyof IStepForm] = props.form[key as keyof IStepForm];
      }

      return mergedForm;
    });
  }, [props.form]);

  const [stepErrors, setStepErrors] = useState<{
    [key: string]: boolean;
  }>({
    name: false,
    email: false,
    cpf: false,
    phone: false,
  });

  const validateCpf = (cpf: string) => {
    try {
      const valCpf = cpf.replace('.', '').replace('.', '').replace('-', '');
      let soma = 0;
      let resto;

      if (
        valCpf === '00000000000' ||
        valCpf === '11111111111' ||
        valCpf === '22222222222' ||
        valCpf === '33333333333' ||
        valCpf === '44444444444' ||
        valCpf === '55555555555' ||
        valCpf === '66666666666' ||
        valCpf === '77777777777' ||
        valCpf === '88888888888' ||
        valCpf === '99999999999' ||
        valCpf === '' ||
        valCpf.length !== 11
      )
        return false;

      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(valCpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(valCpf.substring(9, 10))) return false;
      soma = 0;

      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(valCpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(valCpf.substring(10, 11))) return false;

      return true;
    } catch {
      return false;
    }
  };

  const validateInputs = () => {
    let hasErrors = false;

    for (const key in stepForm) {
      if (
        !stepForm[key as keyof IStepForm] ||
        stepForm[key as keyof IStepForm] === ''
      ) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }

      if (key === 'cpf' && !validateCpf(stepForm[key as keyof IStepForm])) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }

      if (key === 'phone' && stepForm[key as keyof IStepForm].length !== 15) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }
    }

    return hasErrors;
  };

  const TermsAndConditionsLink = () => (
    <span>
      Eu li e concordo com os {''}
      <StyledLink
        href="https://igtieadstorage.blob.core.windows.net/pdfs/[Regulamento]%20TRIAL%20e%20BOOTCAMP%20PASS%2005_2024.docx.pdf"
        target="_blank"
      >
        Termos e Condições
      </StyledLink>
    </span>
  );

  const createUser = async (data: { [key: string]: string }) => {
    setSpinner(true);
    try {
      const {
        data: { success, response },
      } = await api.post('/trial/users', data, {
        timeout: 60000,
      });

      setSpinner(false);
      if (success) {
        setUserCreated(success);
        if (props.setToken) {
          props.setToken(() => {
            return response;
          });
        }
      } else {
        addToast({
          type: 'error',
          title: 'Não foi possível iniciar a inscrição no Trial',
          description: response,
          time: 10000,
        });
      }
    } catch (error: any) {
      setSpinner(false);
      addToast({
        type: 'error',
        title: 'Não foi possível iniciar a inscrição no Trial',
        description: error.response.data.response || 'Erro interno do servidor',
        time: 10000,
      });
    }
  };

  useEffect(() => {
    if (userCreated) {
      addToast({
        type: 'success',
        title: 'Informações confirmadas',
        description:
          'As informações pessoais foram validadas e registradas com sucesso',
        time: 10000,
      });

      if (props.form.cardNumber) {
        props.updateStep(2);
      } else {
        props.updateStep(1);
      }
    }
  }, [userCreated, addToast, props, stepForm]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateInputs()) {
      const formToUse = {
        name: stepForm.name.trim(),
        email: stepForm.email.toLowerCase().trim(),
        cpf: stepForm.cpf,
        phone: stepForm.phone,
      };

      props.updateForm((prev: ICompleteForm) => {
        return { ...prev, ...formToUse };
      });

      await createUser({
        name: formToUse.name,
        email: formToUse.email,
        cpf: formToUse.cpf,
        phone: formToUse.phone,
      });

      // if (props.updateTracker) {
      //   props.updateTracker((prev: ITracker) => {
      //     return { ...prev, personal_data: 'submit-button-click' };
      //   });
      // }

      // setStepTracker((prev) => {
      //   return { ...prev, personal_data: 'continue-buttom-click' };
      // });
    }
  };

  return (
    <MainForm onSubmit={handleSubmit}>
      {spinner ? (
        <BlockedDiv>
          <CircularSpinner />
        </BlockedDiv>
      ) : (
        <></>
      )}

      <StyledTextContainer>
        <StyledHeading>
          Chegou o seu PASSE LIVRE para acessar mais de 2500 horas de conteúdos
          em Tech por 15 dias!
        </StyledHeading>
        <StyledParagraph>
          Durante esse período de <b>imersão a custo zero</b>, você terá{' '}
          <b>acesso a TODOS os Bootcamps</b> do nosso portfólio, assim como
          qualquer outro assinante. Isso significa que você pode fazer quantos
          Bootcamps conseguir sem custo algum.
        </StyledParagraph>
        <StyledParagraph>
          <b>Aproveite essa oportunidade</b>
          para adquirir conhecimentos valiosos para a sua carreira e{' '}
          <b>
            conhecer a expertise do nosso corpo docente antes de ativar a sua
            assinatura!
          </b>
        </StyledParagraph>
        <StyledParagraph>
          <b>
            Preencha o formulário abaixo para resgatar seu acesso grátis e sem
            compromisso:
          </b>
        </StyledParagraph>
      </StyledTextContainer>

      {stepErrors.name ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha seu nome corretamente
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label={TrialInputsLabels.name}
        placeholder="João da Silva"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, name: value };
          });
          setStepErrors((prev) => {
            return { ...prev, name: false };
          });
        }}
        value={stepForm.name}
        inputProps={{ maxLength: 150 }}
      />
      {stepErrors.email ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha seu email corretamente
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label={TrialInputsLabels.email}
        type="email"
        placeholder="trial@xpe.com.br"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, email: value };
          });
          setStepErrors((prev) => {
            return { ...prev, email: false };
          });
        }}
        value={stepForm.email}
        inputProps={{ maxLength: 200 }}
      />
      {stepErrors.cpf ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, informe um cpf válido
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label={TrialInputsLabels.cpf}
        placeholder="000.000.000-00"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, cpf: value };
          });
          setStepErrors((prev) => {
            return { ...prev, cpf: false };
          });
        }}
        value={stepForm.cpf}
        InputProps={{
          inputComponent: CpfMask as any,
        }}
      />
      {stepErrors.phone ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha seu telefone corretamente
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label={TrialInputsLabels.phone}
        placeholder="(99) 99999-9999"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, phone: value };
          });
          setStepErrors((prev) => {
            return { ...prev, phone: false };
          });
        }}
        value={stepForm.phone}
        InputProps={{ inputComponent: PhoneMask as any }}
      />
      {stepErrors.linkedin ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha seu linkedin corretamente
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <StyledTextContainer>
        <FormControlLabel
          style={{ width: '100%', display: 'flex', margin: '5px' }}
          control={
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              required
              sx={{
                color: 'rgba(200, 200, 200, 0.85)',
                '&.Mui-checked': {
                  color: 'rgb(125, 221, 0)',
                },
              }}
            />
          }
          label={TermsAndConditionsLink()}
          sx={{
            maxWidth: '600px',
            marginTop: '-10px !important',
            color: 'rgba(200, 200, 200, 0.85)',
            fontSize: '14px',
            '& .MuiTypography-root': {
              fontSize: '14px',
              fontFamily: 'Radio Canada, sans-serif',
            },
            span: {
              marginLeft: '-2px',
              marginTop: '-2px',
            },
            svg: {
              marginLeft: '5px',
            },
          }}
        />
      </StyledTextContainer>
      <ButtonsDivRadioForm>
        <ProgressButton
          disabled={
            stepForm.name.length === 0 ||
            stepForm.email.length === 0 ||
            stepForm.cpf.length === 0 ||
            stepForm.phone.length === 0 ||
            !checked
          }
          type="submit"
          endIcon={<EastIcon />}
        >
          Continuar
        </ProgressButton>
      </ButtonsDivRadioForm>
    </MainForm>
  );
};

export { Step1 };
