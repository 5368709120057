import EastIcon from '@mui/icons-material/East';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { AlertIcon } from '../components/icons';
import {
  BackButton,
  ButtonsDiv,
  MainForm,
  ProgressButton,
  StyledInputAlert,
  StyledLabel,
  StyledMenuItem,
  StyledSelect,
  SubSelectDiv,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';
import { ICompleteForm } from '../scholarship-ai';

interface IStepForm {
  selectedValue: string;
  crypted_id: string;
}


const Step1Product = (props: IProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setStepForm((prev) => {
      const mergedForm = { ...prev };

      for (const key in mergedForm) {
        mergedForm[key as keyof IStepForm] = props.form[key as keyof IStepForm];
      }

      return mergedForm;
    });
  }, [props.form]);

  const [stepForm, setStepForm] = useState<IStepForm>({
    selectedValue: '',
    crypted_id: '',
  });

  const [stepErrors, setStepErrors] = useState(false);


  const handleSelectChange = (event: SelectChangeEvent, key: any) => {
    const { value } = event.target;
    
    props.updateForm((prev: ICompleteForm) => ({
      ...prev,
      crypted_id: value, 
      selectedValue: key.props.children,
      course: key.props.children,
    }));
  };

  const handleBack = () => {
    props.updateStep(0);
  };

  const validateRadios = () => {
    let hasErrors = false;
  
    for (const key in stepForm) {
      if (!stepForm[key as keyof IStepForm] || stepForm[key as keyof IStepForm] === '') {
        hasErrors = true;
        break; // Pare o loop assim que encontrar um erro
      }
    }
  
    setStepErrors(hasErrors);
  
    return hasErrors;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const urlParams = new URL(window.location.href).searchParams;

    if (!validateRadios()) {
      props.updateStep(2);
      navigate(`/scholarship-ai/${props.form.selectedValue}?${urlParams}`);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <MainForm onSubmit={handleSubmit}>
      
      <SubSelectDiv alertMessage={stepErrors}>
        {stepErrors ? (
              <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
                Por favor, selecione um curso
              </StyledInputAlert>
            ) : (
              <></>
        )}
        <div className='select-area'>
          <StyledLabel>Selecione o curso:</StyledLabel>

          <StyledSelect
            labelId='select-product-label'
            id='select-product'
            value={stepForm.crypted_id || 'Selecione'}
            onChange={handleSelectChange}
          >
            <StyledMenuItem disabled selected value="Selecione">
                <em>Selecione</em>
            </StyledMenuItem>
            {props.courses ? props.courses.map(({ crypted_id, name, exhibition_name }) => {
              return (
                <StyledMenuItem value={crypted_id} key={exhibition_name || name}>
                  {exhibition_name || name}
                </StyledMenuItem>
              );
            }) : <></>};
          </StyledSelect>
        </div>
      </SubSelectDiv>

      
      <ButtonsDiv>
        <ProgressButton type="submit" endIcon={<EastIcon />}>
          Continuar
        </ProgressButton>
        <BackButton type="button" variant="outlined" onClick={handleBack}>
          Voltar
        </BackButton>
      </ButtonsDiv>

    </MainForm>
  );
};

export { Step1Product };
