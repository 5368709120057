import { Checkbox, Container, FormControlLabel } from '@mui/material';
import { useState, useEffect, FormEvent, useCallback } from 'react';
import EastIcon from '@mui/icons-material/East';
import {
  MainForm,
  ProgressButton,
  BackButton,
  LargeInput,
  SmallInput,
  StyledDatePicker,
  StyledInputAlert,
  ButtonsDivRadioForm,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';
import { ICompleteForm } from '../trial';
import { CpfMask, MaskCardNumber } from '../components/masks';
import { TrialInputsLabels, regexCvv } from '../utils/constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AlertIcon } from '../components/icons';
import api from '../utils/api-config';
import {
  BlockedDiv,
  CircularSpinner,
} from '../../../shared/components/spinners';
import { useToast } from '../../../shared/components/Toast';

interface IStepForm {
  cardNumber: string;
  banner: string;
  cardName: string;
  expiryDate: string;
  cvv: string;
  cardCpf: string;
}

const Step2 = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setStepForm((prev) => {
      const mergedForm = { ...prev };

      for (const key in mergedForm) {
        mergedForm[key as keyof IStepForm] = props.form[key as keyof IStepForm];
      }

      return mergedForm;
    });
  }, [props.form]);

  const [checked, setChecked] = useState(false);
  const [verifiedCard, setVerifiedCard] = useState(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const { addToast } = useToast();

  const [stepForm, setStepForm] = useState<IStepForm>({
    cardNumber: '',
    banner: '',
    cardName: '',
    expiryDate: '',
    cvv: '',
    cardCpf: '',
  });

  const [stepErrors, setStepErrors] = useState<{
    [key: string]: boolean;
  }>({
    cardNumber: false,
    banner: false,
    cardName: false,
    expiryDate: false,
    cvv: false,
    cardCpf: false,
  });

  const handleBack = () => {
    props.updateStep(0);
  };

  /**Função que valida o número do cartão, determinando sua bandeira */
  const handleValidateCardNumber = useCallback(() => {
    let validCardNumber = false;
    setStepForm((prev) => {
      return { ...prev, banner: '' };
    });

    /**Objeto contendo os regex de validação de cada bandeira do cartão */
    const banners = {
      Visa: /^4[0-9]{12}(?:[0-9]{3})/,
      Mastercard:
        /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}/,
      Amex: /^3[47][0-9]{13}/,
      ELO: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
    };

    for (const banner in banners)
      if (
        stepForm.cardNumber
          .replaceAll(' ', '')
          .match(banners[banner as 'Visa' | 'Mastercard' | 'Amex' | 'ELO'])
      ) {
        validCardNumber = true;
        setStepForm((prev) => {
          return { ...prev, banner: banner };
        });
        setStepErrors((prev) => {
          return { ...prev, banner: false };
        });
      }
    return validCardNumber;
  }, [stepForm]);

  /**Função que valida a data de vencimento do cartão */
  const handleValidateExpiryDate = useCallback(() => {
    const [month, year] = stepForm.expiryDate.split('/');

    return (
      new Date(`${year}-${month}`).getTime() > new Date(Date.now()).getTime()
    );
  }, [stepForm]);

  const validateCpf = (cpf: string) => {
    try {
      const valCpf = cpf.replace('.', '').replace('.', '').replace('-', '');
      let soma = 0;
      let resto;

      if (
        valCpf === '00000000000' ||
        valCpf === '11111111111' ||
        valCpf === '22222222222' ||
        valCpf === '33333333333' ||
        valCpf === '44444444444' ||
        valCpf === '55555555555' ||
        valCpf === '66666666666' ||
        valCpf === '77777777777' ||
        valCpf === '88888888888' ||
        valCpf === '99999999999' ||
        valCpf === '' ||
        valCpf.length !== 11
      )
        return false;

      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(valCpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(valCpf.substring(9, 10))) return false;
      soma = 0;

      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(valCpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(valCpf.substring(10, 11))) return false;

      return true;
    } catch {
      return false;
    }
  };

  const validateInputs = () => {
    let hasErrors = false;

    for (const key in stepForm) {
      if (key === 'cvv' && !regexCvv.test(stepForm.cvv)) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }

      if (key === 'expiryDate' && !handleValidateExpiryDate()) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }

      if (key === 'cardNumber' && !handleValidateCardNumber()) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }

      if (key === 'cardCpf' && !validateCpf(stepForm[key as keyof IStepForm])) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }
    }
    return hasErrors;
  };

  const checkCard = async (data: { [key: string]: string }) => {
    setSpinner(true);
    try {
      const {
        data: { success, response },
      } = await api.post('/trial/cards', data, {
        timeout: 30000,
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      setSpinner(false);
      if (success) {
        setVerifiedCard(true);
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao validar cartão',
          description: response,
          time: 10000,
        });
      }
    } catch (error: any) {
      setSpinner(false);
      addToast({
        type: 'error',
        title: 'Erro ao validar cartão',
        description: error.response.data.response || 'Erro interno do servidor',
        time: 10000,
      });
    }
  };

  useEffect(() => {
    if (verifiedCard) {
      addToast({
        type: 'success',
        title: 'Informações confirmadas',
        description:
          'As informações de pagamento foram validadas e registradas com sucesso',
        time: 10000,
      });

      props.updateForm((prev: ICompleteForm) => {
        return { ...prev, ...stepForm };
      });
      props.updateStep(2);
    }
  }, [verifiedCard, addToast, props, stepForm]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateInputs()) {
      const formToUse = {
        cardNumber: stepForm.cardNumber.replace(/\s/g, ''),
        cardName: stepForm.cardName,
        expirationMonth: stepForm.expiryDate.split('/')[0],
        expirationYear: stepForm.expiryDate.split('/')[1],
        securityCode: stepForm.cvv,
      };

      await checkCard({
        card_number: formToUse.cardNumber,
        cardholder_name: formToUse.cardName,
        expiration_month: formToUse.expirationMonth,
        expiration_year: formToUse.expirationYear,
        security_code: formToUse.securityCode,
      });
    }
  };

  return (
    <MainForm onSubmit={handleSubmit}>
      {spinner ? (
        <BlockedDiv>
          {/* Estamos conferindo as informações de pagamento, aguarde alguns
          instantes. */}
          <CircularSpinner />
        </BlockedDiv>
      ) : (
        <></>
      )}

      <LargeInput
        focused
        required
        label={TrialInputsLabels.cardName}
        placeholder="João da Silva"
        // className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, cardName: value };
          });
          setStepErrors((prev) => {
            return { ...prev, cardName: false };
          });
        }}
        value={stepForm.cardName}
        inputProps={{ maxLength: 150 }}
      />

      {stepErrors.cardCpf ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, informe um cpf válido
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label={TrialInputsLabels.cardCpf}
        placeholder="000.000.000-00"
        // className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, cardCpf: value };
          });
          setStepErrors((prev) => {
            return { ...prev, cardCpf: false };
          });
        }}
        value={stepForm.cardCpf}
        InputProps={{
          inputComponent: CpfMask as any,
        }}
      />

      {stepErrors.cardNumber ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha o número do seu cartão corretamente.
        </StyledInputAlert>
      ) : (
        <></>
      )}

      <Container
        style={{
          width: 'min(600px, 90vw)',
          padding: '0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <LargeInput
          focused
          required
          id="cardnumber"
          placeholder="0000 0000 0000 0000"
          // className={InputField}
          label={TrialInputsLabels.cardNumber}
          value={stepForm.cardNumber}
          onChange={({ target: { value } }) => {
            setStepForm((prev) => {
              return { ...prev, cardNumber: value };
            });
            setStepErrors((prev) => {
              return { ...prev, cardNumber: false };
            });
          }}
          InputProps={{
            inputComponent: MaskCardNumber as any,
          }}
          sx={{ maxWidth: `${stepForm.banner !== '' && '90%'}` }}
          onBlur={() => handleValidateCardNumber()}
        ></LargeInput>
        {stepForm.banner !== '' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid rgba(200, 200, 200, 0.25)',
              borderRadius: '4px',
              marginLeft: '5px',
              marginTop: '2px',
            }}
          >
            <img
              width={50}
              height={50}
              src={`${stepForm.banner}.svg`}
              alt={TrialInputsLabels.banner}
            />
          </div>
        )}
      </Container>

      {stepErrors.expiryDate ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha a data de validade corretamente.
        </StyledInputAlert>
      ) : (
        <></>
      )}

      {stepErrors.cvv ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha o código corretamente.
        </StyledInputAlert>
      ) : (
        <></>
      )}

      <Container
        style={{
          width: 'min(600px, 90vw)',
          padding: '0',
          display: 'flex',
          justifyContent: 'space-between',
          zIndex: '3 !important',
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="pt"
          localeText={
            ptBR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <StyledDatePicker
            value={
              stepForm.expiryDate
                ? new AdapterDayjs().dayjs(stepForm.expiryDate, 'MM/YYYY')
                : null
            }
            label="Validade"
            views={['month', 'year']}
            format="MM/YYYY"
            slotProps={{
              textField: {
                focused: true,
                placeholder: 'MM/AAAA',
                InputLabelProps: { shrink: true },
              },
            }}
            //@ts-ignore
            onChange={(event: Dayjs | null) => {
              const { $M, $y } = event || {};
              setStepForm((prev) => {
                return {
                  ...prev,
                  expiryDate:
                    $M !== undefined && $y !== undefined
                      ? `${($M + 1).toString().padStart(2, '0')}/${$y}`
                      : '',
                };
              });
              setStepErrors((prev) => {
                return { ...prev, expiryDate: false };
              });
            }}
          />
        </LocalizationProvider>

        <SmallInput
          focused
          required
          label={TrialInputsLabels.cvv}
          id="cvv"
          sx={{ marginLeft: '8px' }}
          inputProps={{ minLength: 3, maxLength: 4 }}
          value={stepForm.cvv}
          onChange={({ target: { value } }) => {
            setStepForm((prev) => {
              return { ...prev, cvv: value };
            });
            setStepErrors((prev) => {
              return { ...prev, cvv: false };
            });
          }}
        />
      </Container>

      <FormControlLabel
        control={
          <Checkbox
            required
            checked={checked}
            onChange={() => setChecked(!checked)}
            sx={{
              color: 'rgba(200, 200, 200, 0.85)',
              '&.Mui-checked': {
                color: 'rgb(125, 221, 0)',
              },
            }}
          />
        }
        sx={{
          maxWidth: '600px',
          marginTop: '-6px !important',
          color: 'rgba(200, 200, 200, 0.85)',
          fontSize: '14px',
          marginLeft: '10px',
          '& .MuiTypography-root': {
            fontSize: '14px',
            fontFamily: 'Radio Canada, sans-serif',
          },
          span: {
            marginLeft: '-2px',
            marginTop: '-2px',
          },
          svg: {
            marginLeft: '5px',
          },
        }}
        label="Concordo com a cobrança nesse cartão caso a inscrição não seja cancelada dentro dos 15 dias de trial"
      />

      <ButtonsDivRadioForm>
        <ProgressButton
          disabled={
            stepForm.cardName.length === 0 ||
            stepForm.cardCpf.length === 0 ||
            stepForm.cardNumber.length === 0 ||
            stepForm.cvv.length === 0 ||
            !checked
          }
          type="submit"
          endIcon={<EastIcon />}
        >
          Continuar
        </ProgressButton>
        {/* <BackButton type="button" variant="outlined" onClick={handleBack}>
          Voltar
        </BackButton> */}
      </ButtonsDivRadioForm>
    </MainForm>
  );
};

export { Step2 };
