import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './main/App';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-WB3WMRS',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
