import { Radio, RadioGroup } from '@mui/material';
import { useState, useEffect, FormEvent } from 'react';
import EastIcon from '@mui/icons-material/East';
import {
  MainForm,
  RadioGroupDiv,
  ProgressButton,
  BackButton,
  RadioLabel,
  ButtonsDivRadioForm,
  StyledDuolineFormLabel,
  StyledAlert,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';
import { ICompleteForm } from '../scholarship-ai';
import { indexToLetter } from '../utils';
import { AlertIcon } from '../components/icons';
import { ICourseQuestions4 } from '../questions';

interface IStepForm {
  motivations: string;
}

export interface IQuestion4 {
  question: string;
  answers: string[];
  map: keyof IStepForm;
}

const Step4 = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setStepForm((prev) => {
      const mergedForm = { ...prev };

      for (const key in mergedForm) {
        mergedForm[key as keyof IStepForm] = props.form[key as keyof IStepForm];
      }

      return mergedForm;
    });
  }, [props.form]);

  const [stepForm, setStepForm] = useState<IStepForm>({
    motivations: '',
  });

  const [stepErrors, setStepErrors] = useState<{
    [key: string]: boolean;
  }>({
    motivations: false,
  });

  const questions: IQuestion4[] | undefined = ICourseQuestions4.find(item => item.crypted_id === props.form.crypted_id)?.questions;

  const handleBack = () => {
    props.updateStep(3);
  };

  const validateRadios = () => {
    let hasErrors = false;

    for (const key in stepForm) {
      if (
        !stepForm[key as keyof IStepForm] ||
        stepForm[key as keyof IStepForm] === ''
      ) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }
    }

    return hasErrors;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateRadios()) {
      props.updateStep(5);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <MainForm onSubmit={handleSubmit}>
      {questions && questions.map(({ question, answers, map }, index) => (
        <RadioGroupDiv key={index}>
          {stepErrors[map] ? (
            <StyledAlert icon={<AlertIcon fontSize="inherit" />} key={index}>
              Por favor, responda a questão abaixo
            </StyledAlert>
          ) : (
            <></>
          )}
          <RadioLabel>{question}</RadioLabel>
          <RadioGroup
            value={stepForm[map]}
            onChange={({ target: { value } }) => {
              setStepForm((prev) => {
                return { ...prev, [map]: value };
              });
              props.updateForm((prev: ICompleteForm) => {
                return { ...prev, [map]: value };
              });
              setStepErrors((prev) => {
                return { ...prev, [map]: false };
              });
            }}
          >
            {answers.map((answer, index) => (
              <StyledDuolineFormLabel
                key={index}
                value={indexToLetter(index)}
                control={<Radio />}
                label={answer}
              />
            ))}
          </RadioGroup>
        </RadioGroupDiv>
      ))}
      <ButtonsDivRadioForm>
        <ProgressButton type="submit" endIcon={<EastIcon />}>
          Continuar
        </ProgressButton>
        <BackButton type="button" variant="outlined" onClick={handleBack}>
          Voltar
        </BackButton>
      </ButtonsDivRadioForm>
    </MainForm>
  );
};

export { Step4 };
