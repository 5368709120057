import { Step, StepLabel } from '@mui/material';
import HeaderCircled from '../../shared/page-structure/header-circled';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Step1Icon, Step2Icon, Step3Icon, Step4Icon } from './components/icons';
import { StyledStepper, MobileStepper } from './components/shared-components';
import { Step1, Step2, Step3, Step4 } from './steps';
import FooterBlack from '../../shared/page-structure/footer-black';
import { MainContainer } from '../../shared/page-structure/main-container';
import { ToastProvider } from '../../shared/components/Toast';

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  font-family: 'Radio Canada', sans-serif !important;

  @media (min-width: 1200px) {
    padding-top: 80px;
    padding-bottom: 50px;
  }
`;

export interface ICompleteForm {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  cardNumber: string;
  banner: string;
  cardName: string;
  cardCpf: string;
  expiryDate: string;
  cvv: string;
}

export interface ITracker {
  personal_data: string;
  card_data: string;
  confirm_data: string;
  canvas_redirect: string;
}

const Trial = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const [completeForm, setCompleteForm] = useState<ICompleteForm>({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    cardNumber: '',
    banner: '',
    cardName: '',
    cardCpf: '',
    expiryDate: '',
    cvv: '',
  });

  const [token, setToken] = useState('');

  // const [tracker, setTracker] = useState<ITracker>({
  //   personal_data: '',
  //   card_data: '',
  //   confirm_data: '',
  //   canvas_redirect: '',
  // });

  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setCurrentWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  const steps = [
    {
      label: 'Dados Pessoais',
      icon: Step1Icon,
      mobileIcon: <Step1Icon />,
      step: (
        <Step1
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          setToken={setToken}
          // updateTracker={setTracker}
          form={completeForm}
        />
      ),
    },
    {
      label: 'Dados do Cartão',
      icon: Step2Icon,
      mobileIcon: <Step2Icon />,
      step: (
        <Step2
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          form={completeForm}
          token={token}
        />
      ),
    },
    {
      label: 'Confirmação',
      icon: Step3Icon,
      mobileIcon: <Step3Icon />,
      step: (
        <Step3
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          form={completeForm}
          token={token}
        />
      ),
    },
    {
      label: 'Concluído',
      icon: Step4Icon,
      mobileIcon: <Step4Icon />,
      step: (
        <Step4
          updateStep={setActiveStep}
          updateForm={setCompleteForm}
          form={completeForm}
        />
      ),
    },
  ];

  return (
    <>
      <MainContainer>
        <HeaderCircled />
        <ToastProvider>
          <MainDiv>
            {currentWidth > 768 ? (
              <StyledStepper activeStep={activeStep} alternativeLabel>
                {steps.map((item) => (
                  <Step key={item.label}>
                    <StepLabel
                      StepIconComponent={() => <item.icon />}
                      onClick={() => {}}
                    >
                      {item.label}
                    </StepLabel>
                  </Step>
                ))}
              </StyledStepper>
            ) : (
              <MobileStepper>
                {steps[activeStep].mobileIcon}
                <p>{steps[activeStep].label}</p>
              </MobileStepper>
            )}
            {steps[activeStep].step}
          </MainDiv>
        </ToastProvider>
      </MainContainer>
      <FooterBlack />
    </>
  );
};

export default Trial;
