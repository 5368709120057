import EastIcon from '@mui/icons-material/East';
import { Radio, RadioGroup } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  BlockedDiv,
  CircularSpinner,
} from '../../../shared/components/spinners';
import { AlertIcon } from '../components/icons';
import {
  BackButton,
  ButtonsDivRadioForm,
  MainForm,
  ProgressButton,
  RadioGroupDiv,
  RadioLabel,
  StyledAlert,
  StyledDuolineFormLabel,
  StyledSyntaxHighlighter,
  StyledTrilineFormLabel,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';
import { ICourseQuestions5 } from '../questions';
import { ICompleteForm } from '../scholarship-ai';
import { convertToQuestions, indexToLetter } from '../utils';
import api from '../utils/api-config';

interface IStepForm {
  code1: string;
  code2: string;
}

export interface IQuestion5 {
  question: string;
  answers: string[];
  map: keyof IStepForm;
  code: string;
}

const Step5 = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setStepForm((prev) => {
      const mergedForm = { ...prev };

      for (const key in mergedForm) {
        mergedForm[key as keyof IStepForm] = props.form[key as keyof IStepForm];
      }

      return mergedForm;
    });
  }, [props.form]);

  const [stepForm, setStepForm] = useState<IStepForm>({
    code1: '',
    code2: '',
  });

  const [stepErrors, setStepErrors] = useState<{
    [key: string]: boolean;
  }>({
    code1: false,
    code2: false,
  });

  const [spinner, setSpinner] = useState<boolean>(false);

  const questions: IQuestion5[] | undefined = ICourseQuestions5.find(
    (item) => item.crypted_id === props.form.crypted_id,
  )?.questions;

  const handleBack = () => {
    props.updateStep(4);
  };

  const validateRadios = () => {
    let hasErrors = false;

    for (const key in stepForm) {
      if (
        !stepForm[key as keyof IStepForm] ||
        stepForm[key as keyof IStepForm] === ''
      ) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }
    }

    return hasErrors;
  };

  const sendData = async (data: { [key: string]: string }) => {
    setSpinner(true);
    try {
      const {
        data: {
          success,
          response: { url },
        },
      } = await api.post('/scholarship-ai/conclude', data, {
        timeout: 60000,
      });

      setSpinner(false);
      if (success) {
        // montar url de retorno
        console.log(props.tracker);
        const queryParams: string[] = [];
        if (props.tracker) {
          if (props.tracker.utm_source) {
            queryParams.push(`utm_source=${props.tracker.utm_source}`);
          }

          if (props.tracker.utm_campaign) {
            queryParams.push(`utm_campaign=${props.tracker.utm_campaign}`);
          }

          if (props.tracker.utm_content) {
            queryParams.push(`utm_content=${props.tracker.utm_content}`);
          }

          if (props.tracker.utm_medium) {
            queryParams.push(`utm_medium=${props.tracker.utm_medium}`);
          }

          if (props.tracker.productAcronym) {
            queryParams.push(
              `igti_checkout_products=${props.tracker.productAcronym}`,
            );
          }
        }
        const url_params = new URLSearchParams(url);

        let finalUrl = '';

        if (url_params) {
          finalUrl = `${url}&${queryParams.join('&')}`;
        } else {
          finalUrl = `${url}?${queryParams.join('&')}`;
        }

        if (finalUrl[finalUrl.length - 1] === '&')
          finalUrl = finalUrl.slice(0, -1);

        window.location.href = finalUrl;
      } else {
        window.location.href = 'https://forms.xpeducacao.com.br/erro';
      }
    } catch (error) {
      setSpinner(false);
      window.location.href = 'https://forms.xpeducacao.com.br/erro';
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateRadios()) {
      const dataToSend = convertToQuestions({ ...props.form, ...stepForm });
      sendData(dataToSend);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <MainForm onSubmit={handleSubmit}>
      {spinner ? (
        <BlockedDiv>
          <CircularSpinner />
        </BlockedDiv>
      ) : (
        <></>
      )}
      {questions &&
        questions.map(({ question, answers, map, code }, initialIndex) => (
          <RadioGroupDiv key={initialIndex}>
            {stepErrors[map] ? (
              <StyledAlert
                icon={<AlertIcon fontSize="inherit" />}
                key={initialIndex}
              >
                Por favor, responda a questão abaixo
              </StyledAlert>
            ) : (
              <></>
            )}
            <RadioLabel>{question}</RadioLabel>
            {code && (
              <StyledSyntaxHighlighter
                language="python"
                style={dark}
                showLineNumbers
              >
                {code}
              </StyledSyntaxHighlighter>
            )}
            <RadioGroup
              value={stepForm[map]}
              onChange={({ target: { value } }) => {
                setStepForm((prev) => {
                  return { ...prev, [map]: value };
                });
                props.updateForm((prev: ICompleteForm) => {
                  return { ...prev, [map]: value };
                });
                setStepErrors((prev) => {
                  return { ...prev, [map]: false };
                });
              }}
            >
              {answers.map((answer, index) =>
                initialIndex === 1 ? (
                  <StyledTrilineFormLabel
                    key={index}
                    value={indexToLetter(index)}
                    control={<Radio />}
                    label={answer}
                  />
                ) : (
                  <StyledDuolineFormLabel
                    key={index}
                    value={indexToLetter(index)}
                    control={<Radio />}
                    label={answer}
                  />
                ),
              )}
            </RadioGroup>
          </RadioGroupDiv>
        ))}
      <ButtonsDivRadioForm>
        <ProgressButton type="submit" endIcon={<EastIcon />}>
          Concluir
        </ProgressButton>
        <BackButton type="button" variant="outlined" onClick={handleBack}>
          Voltar
        </BackButton>
      </ButtonsDivRadioForm>
    </MainForm>
  );
};

export { Step5 };
