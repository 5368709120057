import { useState, useEffect, FormEvent } from 'react';
import EastIcon from '@mui/icons-material/East';
import {
  MainForm,
  ProgressButton,
  StyledList,
  StyledContainer,
  StyledHeading,
  StyledHeading2,
  StyledListItemText,
  EditButton,
  ButtonsDiv,
  LeftButtonsDiv,
  SpecificButtonDiv,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';
import { TrialInputKeys, TrialInputsLabels } from '../utils/constants';
import { Checkbox, FormControlLabel, ListItem } from '@mui/material';
import api from '../utils/api-config';
import {
  BlockedDiv,
  CircularSpinner,
} from '../../../shared/components/spinners';
import { useToast } from '../../../shared/components/Toast';

const Step3 = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [checked, setChecked] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const { addToast } = useToast();

  const createSubscription = async (data: { [key: string]: string }) => {
    setSpinner(true);
    try {
      const {
        data: { success },
      } = await api.post('/trial/subscriptions', data, {
        timeout: 30000,
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      setSpinner(false);
      if (success) {
        setIsSubscribed(true);
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao criar sua conta',
          description:
            'Confirme as informações e tente novamente. Se necessário entre em contato com o nosso suporte: Fale Conosco',
          time: 10000,
        });
      }
    } catch (error) {
      setSpinner(false);
      addToast({
        type: 'error',
        title: 'Erro ao criar sua conta',
        description:
          'Confirme as informações e tente novamente. Se necessário entre em contato com o nosso suporte: Fale Conosco',
        time: 10000,
      });
    }
  };

  useEffect(() => {
    if (isSubscribed) {
      props.updateStep(3);
    }
  }, [isSubscribed, addToast, props, checked]);

  const handleEditButton = (infoType: string) => {
    return infoType === 'personal_data'
      ? props.updateStep(0)
      : props.updateStep(1);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (checked) {
      const formToUse = {
        email: props.form.email.toLowerCase().trim(),
        phone: props.form.phone,
      };

      await createSubscription({
        email: formToUse.email,
        phone: formToUse.phone,
      });
    }
  };

  const personalData: (keyof typeof props.form)[] = [
    'name',
    'email',
    'cpf',
    'phone',
  ];
  const paymentDataGroups: (keyof typeof props.form)[][] = [
    ['cardName'],
    ['cardCpf'],
    ['cardNumber', 'banner'],
    ['expiryDate', 'cvv'],
  ];

  const renderListItems = (keys: (keyof typeof props.form)[]) => {
    return keys.map((key) => (
      <ListItem key={key}>
        <StyledListItemText
          primary={`${TrialInputsLabels[key as TrialInputKeys]}:`}
          secondary={`${props.form[key]}`}
        />
      </ListItem>
    ));
  };

  return (
    <MainForm onSubmit={handleSubmit}>
      {spinner ? (
        <BlockedDiv>
          <CircularSpinner />
        </BlockedDiv>
      ) : (
        <></>
      )}

      <StyledHeading>Por favor, confirme seus dados:</StyledHeading>
      <StyledContainer>
        <div>
          <LeftButtonsDiv>
            <StyledHeading2>Dados pessoais</StyledHeading2>
          </LeftButtonsDiv>
          <StyledList>{renderListItems(personalData)}</StyledList>
        </div>

        <div>
          <ButtonsDiv>
            <EditButton onClick={() => handleEditButton('payment_data')}>
              Editar
            </EditButton>
            <StyledHeading2>Dados de pagamento</StyledHeading2>
          </ButtonsDiv>
          <StyledList>
            {paymentDataGroups.map((group, index) => {
              return (
                <div key={`payment_group_${index}`}>
                  {group.includes('banner') ? (
                    <ListItem style={{ display: 'flex' }}>
                      <StyledListItemText
                        primary={'Número do cartão'}
                        secondary={`${props.form['cardNumber']}`}
                      />
                      {/* <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid rgba(200, 200, 200, 0.25)',
                          borderRadius: '4px',
                          marginRight: '10px',
                          marginTop: '5px',
                        }}
                      >
                        <img
                          width={50}
                          height={50}
                          src={`${props.form.banner}.svg`}
                          alt={TrialInputsLabels.banner}
                        />
                      </div> */}
                    </ListItem>
                  ) : group.includes('cvv') ? (
                    <ListItem style={{ display: 'flex' }}>
                      <StyledListItemText
                        primary={'Validade'}
                        secondary={`${props.form['expiryDate']}`}
                      />
                      <StyledListItemText
                        primary={'CVV'}
                        secondary={`${props.form['cvv']}`}
                      />
                    </ListItem>
                  ) : (
                    group.map((key) => (
                      <ListItem key={key}>
                        <StyledListItemText
                          primary={`${
                            TrialInputsLabels[key as TrialInputKeys]
                          }:`}
                          secondary={`${props.form[key]}`}
                        />
                      </ListItem>
                    ))
                  )}
                </div>
              );
            })}
          </StyledList>
        </div>
      </StyledContainer>
      <FormControlLabel
        control={
          <Checkbox
            required
            checked={checked}
            onChange={() => setChecked(!checked)}
            sx={{
              color: 'rgba(200, 200, 200, 0.85)',
              '&.Mui-checked': {
                color: 'rgb(125, 221, 0)',
              },
            }}
          />
        }
        sx={{
          maxWidth: '600px',
          marginTop: '-6px !important',
          color: 'rgba(200, 200, 200, 0.85)',
          fontSize: '14px',
          marginLeft: '10px',
          '& .MuiTypography-root': {
            fontSize: '14px',
            fontFamily: 'Radio Canada, sans-serif',
          },
          span: {
            marginLeft: '-3px',
            marginTop: '-1px',
          },
          svg: {
            marginLeft: '5px',
          },
        }}
        label="Confirmo que os dados inseridos estão corretos e completos."
      />

      <SpecificButtonDiv style={{ width: '100% !important' }}>
        <ProgressButton
          disabled={!checked}
          type="submit"
          endIcon={<EastIcon />}
        >
          Continuar
        </ProgressButton>
      </SpecificButtonDiv>
    </MainForm>
  );
};

export { Step3 };
