export const TrialInputsLabels = {
  name: 'Nome completo',
  email: 'Email',
  cpf: 'CPF',
  phone: 'Telefone',
  cardNumber: 'Número do cartão',
  cardName: 'Nome do titular',
  cardCpf: 'CPF do titular',
  banner: 'Bandeira do cartão',
  expiryDate: 'Validade',
  cvv: 'CVV',
};


export type TrialInputKeys = keyof typeof TrialInputsLabels;

export const regexEmail = /^.{5,}@.{5,}$/;
export const regexPhone = /^\(\d{2}\) \d{5}-\d{4}$/;
export const regexCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
export const regexCvv = /^\d{3,4}$/;