import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCardIcon from '@mui/icons-material/AddCard';
import HowToRegIcon from '@mui/icons-material/HowToReg';
// import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

export const Step1Icon = AccountCircleIcon;
export const Step2Icon = AddCardIcon;
export const Step3Icon = HowToRegIcon;
export const Step4Icon = CheckCircleIcon;
export const AlertIcon = ErrorIcon;
