import EastIcon from '@mui/icons-material/East';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BlockedDiv,
  CircularSpinner,
} from '../../../shared/components/spinners';
import { AlertIcon } from '../components/icons';
import { CpfMask, PhoneMask } from '../components/masks';
import {
  ButtonsDiv,
  CpfMessageLabel,
  LargeInput,
  LinkedInLabel,
  MainForm,
  ProgressButton,
  StyledInputAlert,
} from '../components/shared-components';
import { IProps } from '../interfaces/iprops';
import { ICompleteForm, ITracker } from '../scholarship-ai';
import api from '../utils/api-config';

interface IStepForm {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  linkedin: string;
  course: string;
  selectedValue: string;
  crypted_id: string;
}

interface IStepTracker {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  productAcronym?: string;
}

const Step1 = (props: IProps) => {
  const [stepForm, setStepForm] = useState<IStepForm>({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    linkedin: '',
    course: '',
    selectedValue: '',
    crypted_id: '',
  });

  const [stepTracker, setStepTracker] = useState<IStepTracker>({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    productAcronym: '',
  });

  const [spinner, setSpinner] = useState<boolean>(true);

  const [firstIteration, setFirstIteration] = useState<boolean>(true);

  const routeParams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setStepForm((prev) => {
      const mergedForm = { ...prev };

      for (const key in mergedForm) {
        mergedForm[key as keyof IStepForm] = props.form[key as keyof IStepForm];
      }

      return mergedForm;
    });
  }, [props.form]);

  useEffect(() => {
    if (!firstIteration) return;

    setFirstIteration(false);

    window.scrollTo(0, 0);

    const urlParams = new URL(window.location.href).searchParams;

    const urlName = urlParams.get('nome');
    const urlEmail = urlParams.get('email');
    const urlPhone = urlParams.get('telefone');
    const urlCourse = routeParams.selectedValue;

    const utmCampaign = urlParams.get('utm_campaign');
    const utmContent = urlParams.get('utm_content');
    const utmMedium = urlParams.get('utm_medium');
    const utmSource = urlParams.get('utm_source');

    const productAcronym = urlParams.get('igti_checkout_products');

    if (urlName) {
      if (urlName.length <= 150) {
        setStepForm((prev) => {
          return { ...prev, name: urlName };
        });
      }
    }

    if (urlEmail) {
      if (urlEmail.length <= 200 && urlEmail.includes('@')) {
        setStepForm((prev) => {
          return { ...prev, email: urlEmail.toLowerCase() };
        });
      }
    }

    if (urlPhone) {
      if (urlPhone.length === 15) {
        setStepForm((prev) => {
          return { ...prev, phone: urlPhone };
        });
      }
    }

    if (utmCampaign) {
      setStepTracker((prev) => {
        return { ...prev, utm_campaign: utmCampaign };
      });
    }

    if (utmContent) {
      setStepTracker((prev) => {
        return { ...prev, utm_content: utmContent };
      });
    }

    if (utmMedium) {
      setStepTracker((prev) => {
        return { ...prev, utm_medium: utmMedium };
      });
    }

    if (utmSource) {
      setStepTracker((prev) => {
        return { ...prev, utm_source: utmSource };
      });
    }

    if (productAcronym) {
      setStepTracker((prev) => {
        return { ...prev, productAcronym: productAcronym };
      });
    }

    const getCheckoutProducts = async () => {
      if (props.courses && props.courses.length === 0) {
        setSpinner(true);
        let hasCourse = false;

        try {
          const {
            data: { success, response },
          } = await api.get('/scholarship-ai/products', {
            timeout: 60000,
          });

          if (success) {
            if (props.updateCourses) {
              props.updateCourses(() => {
                return response;
              });
            }

            // verificar se curso da url está na lista retornada pela API
            for (let i = 0; i < response.length; i++) {
              const course = response[i];

              // const courseNames = [course.exhibition_name, course.name, course.acronym]
              const courseNames = [course.acronym];
              if (courseNames.includes(urlCourse)) {
                hasCourse = true;

                // alterar a variavel hasCourse para true, caso encontrado
                if (props.updateHasCourse) {
                  props.updateHasCourse(() => {
                    return hasCourse;
                  });
                }

                // definir o valor de course no formulário
                setStepForm((prev) => {
                  return {
                    ...prev,
                    course: course.name,
                    selectedValue: course.name,
                    crypted_id: course.crypted_id,
                  };
                });

                // props.updateForm((prev: ICompleteForm) => {
                //   return {...prev,
                //     selectedValue: course.name,
                //     course: course.name,
                //     crypted_id: course.crypted_id
                //   };
                // });

                break;
              }
            }

            if (!hasCourse) navigate(`/scholarship-ai?${urlParams}`);
          } else {
            window.location.href = 'https://forms.xpeducacao.com.br/erro';
          }
        } catch (error) {
          // console.log(error);
          window.location.href = 'https://forms.xpeducacao.com.br/erro';
        }
      }

      setSpinner(false);
    };

    getCheckoutProducts();
  }, [props, firstIteration, routeParams.selectedValue, navigate]);

  const [stepErrors, setStepErrors] = useState<{
    [key: string]: boolean;
  }>({
    name: false,
    email: false,
    cpf: false,
    phone: false,
    linkedin: false,
  });

  const validateCpf = (cpf: string) => {
    try {
      const valCpf = cpf.replace('.', '').replace('.', '').replace('-', '');
      let soma = 0;
      let resto;

      if (
        valCpf === '00000000000' ||
        valCpf === '11111111111' ||
        valCpf === '22222222222' ||
        valCpf === '33333333333' ||
        valCpf === '44444444444' ||
        valCpf === '55555555555' ||
        valCpf === '66666666666' ||
        valCpf === '77777777777' ||
        valCpf === '88888888888' ||
        valCpf === '99999999999' ||
        valCpf === '' ||
        valCpf.length !== 11
      )
        return false;

      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(valCpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(valCpf.substring(9, 10))) return false;
      soma = 0;

      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(valCpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(valCpf.substring(10, 11))) return false;

      return true;
    } catch {
      return false;
    }
  };

  const validateInputs = () => {
    let hasErrors = false;

    for (const key in stepForm) {
      if (
        key === 'linkedin' ||
        key === 'course' ||
        key === 'selectedValue' ||
        key === 'crypted_id'
      ) {
        continue;
      }

      if (
        !stepForm[key as keyof IStepForm] ||
        stepForm[key as keyof IStepForm] === ''
      ) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }

      if (key === 'cpf' && !validateCpf(stepForm[key as keyof IStepForm])) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }

      if (key === 'phone' && stepForm[key as keyof IStepForm].length !== 15) {
        hasErrors = true;
        setStepErrors((prev) => {
          return { ...prev, [key]: true };
        });
      }
    }

    return hasErrors;
  };

  const start = async (data: { [key: string]: string }) => {
    setSpinner(true);
    try {
      const {
        data: { success },
      } = await api.post('/scholarship-ai/start', data, {
        timeout: 60000,
      });

      setSpinner(false);
      if (success) {
        return;
      } else {
        window.location.href = 'https://forms.xpeducacao.com.br/erro';
      }
    } catch (error) {
      setSpinner(false);
      window.location.href = 'https://forms.xpeducacao.com.br/erro';
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateInputs()) {
      const formToUse = {
        name: stepForm.name.trim(),
        email: stepForm.email.toLowerCase().trim(),
        cpf: stepForm.cpf,
        phone: stepForm.phone,
        linkedin: stepForm.linkedin.toLowerCase().trim(),
        course: stepForm.course.trim(),
        selectedValue: stepForm.selectedValue.trim(),
        crypted_id: stepForm.crypted_id.trim(),
      };

      const trackerInfos = {
        utm_source: stepTracker.utm_source.trim(),
        utm_medium: stepTracker.utm_medium.trim(),
        utm_campaign: stepTracker.utm_campaign.trim(),
        utm_content: stepTracker.utm_content.trim(),
        productAcronym: stepTracker.productAcronym?.trim(),
      };

      await start({
        name: formToUse.name,
        email: formToUse.email,
        phone: formToUse.phone,
        linkedin: formToUse.linkedin,
        product_name: formToUse.course,
      });

      props.updateForm((prev: ICompleteForm) => {
        return { ...prev, ...formToUse };
      });

      if (props.updateTracker) {
        props.updateTracker((prev: ITracker) => {
          return { ...prev, ...trackerInfos };
        });
      }

      if (props.hasCourse) {
        props.updateStep(2);
      } else {
        props.updateStep(1);
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <MainForm onSubmit={handleSubmit}>
      {spinner ? (
        <BlockedDiv>
          <CircularSpinner />
        </BlockedDiv>
      ) : (
        <></>
      )}

      {stepErrors.name ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha seu nome corretamente
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label="Nome Completo"
        placeholder="João da Silva"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, name: value };
          });
          setStepErrors((prev) => {
            return { ...prev, name: false };
          });
        }}
        value={stepForm.name}
        inputProps={{ maxLength: 150 }}
      />
      {stepErrors.email ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha seu email corretamente
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label="Email"
        type="email"
        placeholder="bolsa-ia@xpe.com.br"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, email: value };
          });
          setStepErrors((prev) => {
            return { ...prev, email: false };
          });
        }}
        value={stepForm.email}
        inputProps={{ maxLength: 200 }}
      />
      {stepErrors.cpf ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, informe um cpf válido
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label="CPF"
        placeholder="000.000.000-00"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, cpf: value };
          });
          setStepErrors((prev) => {
            return { ...prev, cpf: false };
          });
        }}
        value={stepForm.cpf}
        InputProps={{
          inputComponent: CpfMask as any,
        }}
      />
      <CpfMessageLabel>
        Este CPF será usado no seu contrato de matrícula.
      </CpfMessageLabel>
      {stepErrors.phone ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha seu telefone corretamente
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        required
        label="Telefone"
        placeholder="(99) 99999-9999"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, phone: value };
          });
          setStepErrors((prev) => {
            return { ...prev, phone: false };
          });
        }}
        value={stepForm.phone}
        InputProps={{ inputComponent: PhoneMask as any }}
      />
      {stepErrors.linkedin ? (
        <StyledInputAlert icon={<AlertIcon fontSize="inherit" />}>
          Por favor, preencha seu linkedin corretamente
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <LargeInput
        focused
        label="LinkedIn"
        placeholder="https://www.linkedin.com/school/xpeducacao/mycompany/"
        className="inputField"
        onChange={({ target: { value } }) => {
          setStepForm((prev) => {
            return { ...prev, linkedin: value };
          });
          setStepErrors((prev) => {
            return { ...prev, linkedin: false };
          });
        }}
        value={stepForm.linkedin}
        inputProps={{ maxLength: 200 }}
      />
      <LinkedInLabel>
        Insira o link do perfil do seu LinkedIn para participar das nossas ações
        de Marketing. (Opcional)
      </LinkedInLabel>
      <ButtonsDiv>
        <ProgressButton type="submit" endIcon={<EastIcon />}>
          Continuar
        </ProgressButton>
      </ButtonsDiv>
    </MainForm>
  );
};

export { Step1 };
